import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../utils/media'
import { breakpoints, space, mediaQueries } from '../utils/tokens'

import { FlexColumn } from './StyledComponents'
import { CallButton, EmailButton } from './Button'

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${media.desktop`
    flex-direction: row;
  `}
  
`

const Wrapper = styled.div`
  margin: ${space[4]}px auto;
  padding: 0 ${space[4]}px;
  width: 100%;

  ${mediaQueries.md} {
    width: 50%;
    max-width: ${breakpoints.md};
    padding: 0;
  }
`
const GetInTouch = () => {
  return (
    <section>
      <FlexColumn>
        <Wrapper>
          <hr />
          <h3 css={css`
            text-align: center;
            margin-bottom: 2rem;
            font-weight: bold;
            font-size: 1.5rem;

            ${media.desktop`
              margin-bottom: 3rem;
            `}
          `}>Let's talk!</h3>
          <ButtonGroup>
              <CallButton
                text="1 (929) 213-9604"
                full
              />
              <div style={{ margin: `0.2rem`}}/>
              <EmailButton
                text="contact@prtwd.com"
                style={{ textTransform: `lowercase` }}
                full
              />
          </ButtonGroup>
        </Wrapper>
      </FlexColumn>
    </section>
  )
}

export default GetInTouch