import React from 'react'
import { Helmet } from 'react-helmet'

import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { Row, Col } from 'react-flexbox-grid'

import Hero from '../../components/Hero'
import SEO from '../../components/SEO'
import GetInTouch from '../../components/GetInTouch'
import {
  FAQSection,
  HowItWorksSection,
  RecentWorkSection
} from '../../components/Section'

import { CallButton, QuoteButton } from '../../components/Button'

const ButtonGroup = props => (
  <Row style={{
    width: `100%`
  }}>
    <Col xs={12} md={6} style={{
      marginBottom: `0.5rem`
    }}>
      <QuoteButton url={props.typeform} />
    </Col>
    <Col xs={12} md={6}>
      <CallButton text="CALL US: 1 (929) 213-9604" white full />
    </Col>
  </Row>
)

const ServiceMetalPlating = ({ data }) => {
  const { hero, name, metaDescription, slug, typeform, metaTitle } = data.contentfulService

  const postNode = {
    title: metaTitle || `${name} - Service | NYC | PrintAWorld`,
    metaDescription: metaDescription,
  }

  return (
    <div>
      <Layout lightMenu>
        <Helmet>
          <title>{postNode.title}</title>
        </Helmet>
        <SEO postNode={postNode} pagePath={`services/${slug}`} customTitle pageSEO />
        <Hero
          hero={hero}
          height="100vh"
          callToAction={<ButtonGroup typeform={typeform}/>}
          contentLeft
          overlay="none" />
        <RecentWorkSection gallery={data.contentfulService.gallery} />
        <HowItWorksSection steps={data.contentfulService.howItWorks} />
        <FAQSection faqs={data.contentfulService.frequentlyAskedQuestions} />
        <GetInTouch />
      </Layout>
    </div>
  )
}

export const manufacturingPageQuery = graphql`
  query serviceMetalPlating {
    contentfulService(slug: { eq: "metal-plating" }) {
      name
      slug
      typeform
      metaTitle
      metaDescription {
        internal {
          content 
        }
      }
      hero {
        ... on Node {
          ... on ContentfulHeroTextOnly {
            ...HeroTextOnly
          }
          ... on ContentfulHero {
            ...HeroFragment
          }
        }
      }
      howItWorks {
        ...HowItWorksCardFragment
      }
      gallery {
        images {
          ...RecentWorkImages
        }
      }
      frequentlyAskedQuestions {
        question
        id
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default ServiceMetalPlating
